// import Vue from 'vue'
// import Breakpoints from '../vue/breakpoints'
import LazyLoad from "vanilla-lazyload";
import { MatchHeight } from 'js-match-height';

// // Intiate Vuejs instance
// var app = new Vue({
//     el: '#app',
//     delimiters: ["${","}"],
//     components: {
//         Breakpoints,
//     }
// })

// Scripts to fire once dom has loaded
document.addEventListener("DOMContentLoaded", () => {

    //Nav active for child
    let navCurrent = document.querySelector(".current-mobile");
    if(navCurrent) {
        navCurrent.parentElement.parentElement.classList.add("active-parent");
    }

    // Open Menu
    const navTrigger = document.querySelector('.js-open-nav');
    const overlay = document.getElementById("overlay");
    const projectWrapper = document.getElementById('app');

    // Toggle Menu
    bindEvent(navTrigger, "click", function() {
        this.classList.toggle('is-active');
        overlay.classList.toggle('show');
        projectWrapper.classList.toggle('js-is-open');
    }, false);

    // Lazyload
    var lazy = new LazyLoad({
        elements_selector: ".lazy"
    });

    // Accordions
    // If there is an accordion inside an accordion then data-accordion-allow-multiple needs to be used
    const accordionItems = document.querySelectorAll('[data-accordion]');

    if (accordionItems.length) {
        accordionItems.forEach(function(accordion) {
            const accordionLink = accordion.firstElementChild;
            bindEvent(accordionLink, 'click', toggleAccordion);
        });
    }

    function toggleAccordion(e) {
        const link = e.target;
        const parent = link.closest('[data-accordion]');
        const content = parent.querySelectorAll('[data-accordion-content]');

        content.forEach(function(content) {
            if (content.previousElementSibling === link) {
                content.classList.toggle('is-active');
                parent.classList.toggle('is-active');
                link.setAttribute('aria-expanded', link.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
            }
            else {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.classList.remove('is-active');
                    content.previousElementSibling.classList.remove('is-active');
                    content.previousElementSibling.setAttribute('aria-expanded', 'false');
                }
            }
        });
    }

    function bindEvent(el, eventName, eventHandler) {
        if (el.addEventListener){
            el.addEventListener(eventName, eventHandler, false);
        } else if (el.attachEvent){
            el.attachEvent('on'+eventName, eventHandler);
        }
    }

    // Form fields and labels
    var formLabels = document.querySelectorAll('.form-input');

    if (formLabels) {
        formLabels.forEach(function(element) {
            element.addEventListener('blur', function(event) {
                if (!event.target.value){
                    event.target.previousElementSibling.classList.remove('form-label--filled');
                }
            });
            element.addEventListener('focus', function(event) {
                event.target.previousElementSibling.classList.add('form-label--filled');
            });
        })
    }

    // Circle dynamic clip path calculation

    let clipImageHomeMobileWidth, clipImageHomeMobile, maxCircleRadius, circleRadius, clipImageHome, clipYellowCircleSize, clipYellowCircleX, clipYellowCircleY, clipImageHomeWidth, innerWidth, clipYellowCircle, circleLogo, circleLogoLeft;
    

    function createYellowCircle() {
        clipYellowCircle = document.querySelector('.clip-yellow-circle');
        let bannerCircleHome = document.querySelector(".banner--circle__home");
        let header = document.querySelector(".header");

        if(clipYellowCircle) {
            circleLogo = document.querySelector(".logo-desktop-home");
            circleLogoLeft = circleLogo.getBoundingClientRect().left;
            innerWidth = window.innerWidth;
            maxCircleRadius = 480;

            if(innerWidth >= 1280) {
                circleRadius = maxCircleRadius;
                clipYellowCircleSize = circleRadius;
                clipYellowCircleX = clipYellowCircleSize + circleLogoLeft - 100;
                clipYellowCircleY = clipYellowCircleSize - (clipYellowCircleSize * 0.6);
            } else if(innerWidth <= 1280 && innerWidth >= 1024) {
                circleRadius = maxCircleRadius;
                clipYellowCircleSize = circleRadius;
                clipYellowCircleX = clipYellowCircleSize + circleLogoLeft - 100;
                clipYellowCircleY = clipYellowCircleSize - (clipYellowCircleSize * 0.6);
            } else if(innerWidth <= 1024 && innerWidth >= 768) {
                circleRadius = innerWidth;
                clipYellowCircleSize = circleRadius/2;
                clipYellowCircleX = clipYellowCircleSize - (clipYellowCircleSize * 0.3);
                clipYellowCircleY = clipYellowCircleSize - (clipYellowCircleSize * 0.3);                
            } else if(innerWidth <= 768 && innerWidth >= 420) {
                circleRadius = innerWidth;
                clipYellowCircleSize = circleRadius/2.25;
                clipYellowCircleX = clipYellowCircleSize - (clipYellowCircleSize * 0.3);
                clipYellowCircleY = clipYellowCircleSize - (clipYellowCircleSize * 0.3);
            } else if(innerWidth <= 420) {
                clipYellowCircleSize = 250;
                clipYellowCircleX = innerWidth/2 + (innerWidth * 0.02);
                clipYellowCircleY = (clipYellowCircleSize * 0.01 * 60);
            }

            clipYellowCircle.style.clipPath = "circle(" + clipYellowCircleSize + "px at " + clipYellowCircleX + "px " + clipYellowCircleY + "px)";

            clipYellowCircle.style.visibility = "visible";
            clipYellowCircle.style.height  = bannerCircleHome.getBoundingClientRect().height + header.getBoundingClientRect().height + "px";
        }
    }

    function createSuccessYellowCircle() {
        let clipSuccessYellowCircle = document.querySelector('.success-yellowcircle');
        let SuccessYellowCircleRadius = 410, SuccessYellowCircleX = 410, SuccessYellowCircleY = 410;

        let innerWidth = window.innerWidth;
        if(clipSuccessYellowCircle) {

            // if(innerWidth >= 1950) {
            //     clipSuccessYellowCircle.style.right = "-60%";
            //     clipSuccessYellowCircle.style.width = "100%";
            // } else if(innerWidth <= 1950 && innerWidth >= 1800) {
            //     clipSuccessYellowCircle.style.right = "-" + (SuccessYellowCircleRadius - (innerWidth * 0.05)) + "px";
            //     clipSuccessYellowCircle.style.width = "";
            // } else if(innerWidth <= 1800 && innerWidth >= 1280) {
            //     clipSuccessYellowCircle.style.right = "-" + (SuccessYellowCircleRadius - (innerWidth * 0.005)) + "px";
            // } else if(innerWidth <= 1280 && innerWidth >= 1024) {
            //     clipSuccessYellowCircle.style.right = "-" + (SuccessYellowCircleRadius + (innerWidth * 0.02)) + "px";
            // } else if(innerWidth <= 1024 && innerWidth >= 768) { 
            //     clipSuccessYellowCircle.style.right = "-" + (SuccessYellowCircleRadius + (innerWidth * 0.1)) + "px";            
            // } else if(innerWidth <= 768) {
            //     SuccessYellowCircleRadius = 0;
            // } 

            // clipSuccessYellowCircle.style.clipPath = "circle(" + SuccessYellowCircleRadius + "px at " + SuccessYellowCircleX + "px " + SuccessYellowCircleY + "px)";
            clipSuccessYellowCircle.style.visibility = "visible";
        }
    }

    createYellowCircle();
    createSuccessYellowCircle();

    window.addEventListener("resize", function() {
        createYellowCircle();
        createSuccessYellowCircle();
    })

    // team lightbox
    let teamMembers = document.querySelectorAll(".team--member");
    let teamContents = document.querySelectorAll(".team--content");
    let teamOverlay = document.querySelector(".team--overlay");
    let teamCloseOverlay = document.querySelectorAll(".close");

    function getTeamMemberDetails(index) {
        teamContents.forEach(item => {
            if(item.getAttribute("data-team") == index) {
                item.classList.add("show");
            }
        })
        teamOverlay.classList.add("show");
    }

    function removeOverlay() {
        teamContents.forEach(item => {
            item.classList.remove("show");
        })
        teamOverlay.classList.remove("show");
    }

    if(teamMembers) {
        teamMembers.forEach(item => {
            item.addEventListener("click", function() {
                getTeamMemberDetails(this.getAttribute("data-team"));
            })
        })
    }

    if(teamOverlay) {
        teamOverlay.addEventListener("click", function() {
            removeOverlay();
        })
    }
    
    if(teamCloseOverlay) {
        teamCloseOverlay.forEach(item => {
            item.addEventListener("click", function() {
                removeOverlay();
            })
        })
    }

    

    let images = document.querySelectorAll('.animate-image');

    window.addEventListener('scroll', function() {
        images.forEach(item => {
            if(elementInViewport(item)) {
                item.classList.add("animate");
            }
        })
    })
    if(window.innerWidth >= 768) {
        setTimeout(function(){
            const matchHeight1 = new MatchHeight('.tiles .eq1');
            const matchHeight2 = new MatchHeight('.tiles .eq2');
            const matchHeight3 = new MatchHeight('.tiles .eq3');
            const matchHeight4 = new MatchHeight('.tiles .eq4');

            const matchHeight5 = new MatchHeight('.tiles .eqTitle');
        }, 500)
    }
})

// Animating images when on screen
function elementInViewport(elem) {
    var bounding = elem.getBoundingClientRect().top;
    if (bounding - window.innerHeight <= 0) {
        return true;
      }
};




